import Vue from "vue";
import store from "@/store";
import VueApollo from "vue-apollo";
import { InMemoryCache } from "@apollo/client/core";
// import { restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
// import USER_BY_USERNAME from "@/graphql/user/queries/UserByUsername.graphql";
import FIND_OR_CREATE_USER from "@/graphql/user/queries/FindOrCreateUser.graphql";
import { Auth } from "aws-amplify";
import {
  customCreateApolloClient,
  restartWebsockets,
} from "@/plugins/customCreateApolloClient";
import { logTrackingData } from "@/service/trackingServices";

// Install the vue plugin
Vue.use(VueApollo);

let aClient = null;

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:4000/graphql";

// Websocket endpoint
const wsEndpoint =
  process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql";

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: httpEndpoint,

  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: wsEndpoint,

  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,

  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,

  // Is being rendered on the server?
  ssr: false,

  // Override default cache with an implementation that allows evicting entries
  cache: new InMemoryCache(),

  // Override the way the Authorization header is set
  // we must refetch the jwt on the fly as it might have expired
  getAuth: defaultGetAuth,
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = customCreateApolloClient({
    ...defaultOptions,
    ...options,
  });

  aClient = apolloClient;

  // create our own wsClient (don't use the one from createApolloClient() above) to enable the authorization
  // header when establishing the connection to the backend
  // apolloClient.wsClient = new SubscriptionClient(wsEndpoint, {
  //   reconnect: true,
  //   lazy: false,
  //   connectionParams: async () => {
  //     const Authorization = await defaultGetAuth();
  //     let params = Authorization ? { Authorization, headers: { Authorization } } : {};
  //     console.log(params);
  //     return params;
  //   },
  // });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
}

/**
 * Overriding the default auth token fetching from the local storage this methods directly fetches the jwt for
 * the auth header from the Cognito user object. If the current token has expired Amplify will automatically refresh it
 * for us first.
 * Why not fetching it from the local storage? Because there is a timing issue. If using the local storage then the
 * apollo-client first would read the expired jwt from the local storage and only after that Amplify would refresh
 * the token. Therefore the GraphQL query would carry the expired jwt in the request and fail.
 *
 * @returns {Promise<string|string>}
 */
async function defaultGetAuth() {
  try {
    let token = "";
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (cognitoUser) {
      const currentSession = cognitoUser.signInUserSession;
      token = currentSession.accessToken.getJwtToken();
    }
    return token ? `Bearer ${token}` : "";
  } catch (e) {
    console.error("error getting jwt", e);
    return null;
  }
}

// Manually call this when user log in
export async function onLogin(apolloClient, user) {
  console.log("onLogin");
  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
    console.log("login restartWebsockets");
  }
  try {
    await apolloClient.queryManager.fetchQueryRejectFns;
    // await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
  loadUserFromBackend(apolloClient, user);
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  console.log("onLogout");
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.resetStore();
    await store.commit("auth/setNJUser", null);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}

export function loadUserFromBackend(apolloClient, user) {
   apolloClient
    .query({
      query: FIND_OR_CREATE_USER,
      variables: {
        username: user.username,
        providedId: user.attributes.sub,
      },
      skip: !user,
    })
    .then((data) => {
      store.commit("auth/setNJUser", data.data.userdata);

      logTrackingData({
        activityType: "LOGIN",
      });
    })
    .catch((error) =>
      console.error(
        "error fetching NJUser on login: ",
        user ? user.username : null,
        error
      )
    );
}

export function getApolloClient() {
  return aClient;
}
