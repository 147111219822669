var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-authenticator",class:{
    'custom-authenticator--signin': _vm.displayMap.showSignIn,
    'custom-authenticator--signup':
      _vm.displayMap.showSignUp || _vm.displayMap.showConfirmSignUp,
    'custom-authenticator--reset': _vm.displayMap.showForgotPassword,
  }},[_c('div',{staticClass:"custom-authenticator__image-container",class:{
      'custom-authenticator__image-container--reset':
        _vm.displayMap.showForgotPassword,
    }},[_c('img',{staticClass:"custom-authenticator__globe",attrs:{"src":"/globe-full.png","alt":"globe image"}}),(
        _vm.displayMap.showSignUp ||
        _vm.displayMap.showSignIn ||
        _vm.displayMap.showConfirmSignUp
      )?_c('div',{staticClass:"custom-authenticator__image-container-text",class:{
        'custom-authenticator__image-container-text--signin':
          _vm.displayMap.showSignIn,
        'custom-authenticator__image-container-text--signup':
          _vm.displayMap.showSignUp || _vm.displayMap.showConfirmSignUp,
      }},[_c('div',[(_vm.displayMap.showSignIn)?_c('svg',{attrs:{"width":"171","height":"32","viewBox":"0 0 171 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M-17 16L169 16","stroke":"#0564D2","stroke-width":"2"}}),_c('path',{attrs:{"d":"M154 1L169 16L154 31","stroke":"#0564D2","stroke-width":"2"}})]):_vm._e(),_c('h1',{staticClass:"custom-authenticator__image-container-text-welcome"},[_vm._v(" "+_vm._s(_vm.getWelcomeText)+" ")])]),_c('div',[_c('svg',{attrs:{"width":"186","height":"32","viewBox":"0 0 186 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M-17 16L169 16","stroke":"#0564D2","stroke-width":"2"}}),_c('path',{attrs:{"d":"M154 1L169 16L154 31","stroke":"#0564D2","stroke-width":"2"}})]),_c('h1',{staticClass:"custom-authenticator__image-container-text-signin"},[_vm._v(" "+_vm._s(_vm.displayMap.showSignIn ? _vm.$t("custom_authenticator.sign_in") : _vm.$t("custom_authenticator.sign_up"))+" ")])])]):_vm._e()]),_c('div',{staticClass:"custom-authenticator__forms",class:{
      'custom-authenticator__forms--reset':
        _vm.displayMap.showForgotPassword || _vm.displayMap.requireNewPassword,
    }},[(_vm.displayMap.showSignIn)?_c('sign-in',{staticClass:"custom-authenticator__forms-signin",attrs:{"signInConfig":_vm.options.signInConfig}}):_vm._e(),(_vm.displayMap.showSignUp)?_c('sign-up',{staticClass:"custom-authenticator__forms-signup"}):_vm._e(),(_vm.displayMap.showConfirmSignUp)?_c('confirm-sign-up',{attrs:{"confirmSignUpConfig":_vm.options.confirmSignUpConfig}}):_vm._e(),(_vm.displayMap.showForgotPassword)?_c('forgot-password'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }